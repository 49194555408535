import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Form from '../../components/Form'
import Input from '../../components/Input'
import Section from '../../components/Section/Section'
import passwordIcon from '../../assets/images/icon/password.png'
import userIcon from '../../assets/images/icon/user.png'
import { AuthContext } from '../../context/AuthContext';

const SignIn = () => {
  const { login } = useContext(AuthContext);
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!(user || password)) {
      setError('請輸入所有資料');
      return;
    }

    try {
      await login(user, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <Section id='signin' >
      <Form title="登入" onSubmit={handleSubmit} buttonTitle="登入" error={error}>
        <Input image={userIcon} type='text' placeholder='用戶名' name='user' value={user} onChange={setUser} >用戶名</Input>
        <Input image={passwordIcon} type='password' placeholder='密碼' name='password' value={password} onChange={setPassword} >密碼</Input>
      </Form>
    </Section>
  )
}

export default SignIn
