import React, { useState, useEffect, useContext } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import stripeIcon from '../../assets/images/icon/stripe.png'
import paypalIcon from '../../assets/images/icon/paypal.png'
import coinsImg from '../../assets/images/icon/gold.png'
import Section from '../../components/Section/Section'
import Select from '../../components/Select/Select';
import { buyCoins } from "../../utils/api";
import { AuthContext } from '../../context/AuthContext';
import { motion } from 'framer-motion'
import paysafecardIcon from '../../assets/images/icon/paysafecard.png'
import Button from '../../components/Button'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100

const StripePayment = ({ coins, user }) => {
    const [selectedCoin, setSelectedCoin] = useState({
        key: 0,
        value: coins[0].price,
        text: `${coins[0].price} 港幣- ${coins[0].amount} 金幣`
    });
    const [message, setMessage] = useState("");

    const getOptions = () => {
        return coins.map((coin, key) => ({
            key: key,
            text: `${coin.price} 港幣- ${coin.amount} 金幣`,
            value: coin.price
        }));
    };

    const handleSubmit = async () => {
        try {
            const stripe = await stripePromise;
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await fetch(`${process.env.REACT_APP_API_URL}shop/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "JwtToken": user, "Amount": selectedCoin.value * 100 }),
            });

            const session = await response.text();

            if (response.ok) {
                const { error } = await stripe.redirectToCheckout({
                    sessionId: session,
                });

                if (error) {
                    setMessage(error.message);
                }
            } else {
                setMessage('Failed to create a checkout session. Please try again.');
            }
        } catch (error) {
            setMessage('Error occurred: ' + error.message);
        }
    };

    return (
        <div className="stripe section-container">
            <img className='paymentMethod-img' src={stripeIcon} alt="STRIPE" />
            <div className="main-divider"></div>
            <div className="price-container">
                <p className='info'>選擇您想要的硬幣數量，然後進行付款。</p>
                <Select handler={setSelectedCoin} options={getOptions()}>
                    <div className="gold-img image" style={{ backgroundImage: `url(${coinsImg})` }}></div>
                    <p>{selectedCoin.text}</p>
                </Select>
                <Button handler={handleSubmit} className='image' height='42px' width='148px'>支付</Button>
                {message && <p style={{ color: 'green', fontSize: '1.2em', marginTop: '10px' }}>{message}</p>}
            </div>
        </div>
    );
};

const PaypalPayment = ({ coins, user }) => {
    const getOptions = () => {
        return coins.map((coin, key) => ({
            key: key,
            text: `${coin.price} 港幣- ${coin.amount} 金幣`,
            value: coin.price
        }));
    }

    const options = getOptions();
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&currency=${process.env.REACT_APP_PAYPAL_CURRENCY}`;
        script.addEventListener('load', () => {
            if (window.paypal) {
                window.paypal.Buttons({
                    createOrder: (_, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: selectedOption.value
                                }
                            }],
                            application_context: {
                                shipping_preference: 'NO_SHIPPING'
                            }
                        });
                    },
                    onApprove: (_, actions) => {
                        return actions.order.capture().then(async details => {
                            let result = await buyCoins(details.id, details, selectedOption.value, user);

                            if (result.success) {
                                setSuccessMessage('交易成功，金幣已添加到您的帳戶。');
                            }
                        });
                    }
                }).render('#paypal-button-container');
            }
        });
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [selectedOption, user]);

    return (
        <div className="stripe section-container">
            <img className='paymentMethod-img' src={paypalIcon} alt="PayPal" />
            <div className="main-divider"></div>
            <div className="price-container" style={{ zIndex: 9999 }}>
                <p className='info'>選擇您想要的 金幣數量，然後您將被重定向到銷售平台。</p>
                <Select handler={setSelectedOption} options={options}>
                    <div className="gold-img image" style={{ backgroundImage: 'url(' + coinsImg + ')' }}></div>
                    <p>{selectedOption.text}</p>
                </Select>
                <div id="paypal-button-container" style={{ zIndex: 1 }}></div>
                <p className='term'>點擊此按鈕即表示您同意我們的服務條款</p>
                {successMessage && <p style={{ color: 'green', fontSize: '1.2em', marginTop: '10px' }}>{successMessage}</p>}
            </div>
        </div>
    );
}

const Fps = () => {
    return (
        <div className="paysafecard section-container">
            <img className='paymentMethod-img' src={paysafecardIcon} alt="PAYSAFEICON" />
            <div className="main-divider"></div>
            <div className="price-container">
                <p className='info'>要使用 FPS 付款，請直接在 Discord 上聯繫我</p>
            </div>

        </div>
    )
}

const BuyCoins = ({coins}) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("paypal")
    const { user } = useContext(AuthContext);

    const getPayementView = () => {
        switch (selectedPaymentMethod) {
            case "stripe":
                return (
                    <Elements stripe={stripePromise}>
                        <StripePayment coins={coins} user={user} />
                    </Elements>
                );
            case "paypal":
                return <PaypalPayment coins={coins} user={user} />
            case "fps":
                return <Fps />
            default:
                return <PaypalPayment coins={coins} />
        }
    }

    return (
        <Section id='buyCoins'>
            <motion.h1
                            initial={{ opacity: 0, y: -translateY }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -translateY }}
                            transition={transition}
            >選擇付款方式（請在 Discord 上開啟工單以獲取其他付款方式）</motion.h1>
            <motion.div
                initial={{ opacity: 0, y: -translateY }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -translateY }}
                transition={transition}
                className="paymentMethodes">
                <div className={`paymentMethode ${selectedPaymentMethod === "paypal" ? "active" : undefined}`} onClick={() => setSelectedPaymentMethod("paypal")}><img src={paypalIcon} alt='paypal' /></div>
                <div className={`paymentMethode ${selectedPaymentMethod === "stripe" ? "active" : undefined}`} onClick={() => setSelectedPaymentMethod("stripe")}><img src={stripeIcon} alt='STRIPE' /></div>
                <div className={`paymentMethode ${selectedPaymentMethod === "fps" ? "active" : undefined}`} onClick={() => setSelectedPaymentMethod("fps")}><img src={paysafecardIcon} alt='fps' /></div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: translateY }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: translateY }}
                transition={transition}
            >
                {getPayementView()}
            </motion.div>
        </Section>
    )
}

export default BuyCoins
