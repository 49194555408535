import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './css/styles.css'
import { BrowserRouter as Router } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import { AuthProvider } from './context/AuthContext';

const root = createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AuthProvider>
      <CartProvider>
        <App />
      </CartProvider>
    </AuthProvider>
  </Router>
);