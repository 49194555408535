import React from 'react'

const Input = ({name, placeholder, type, value, onChange, image, children}) => {
    return (
        <div className="input">
            <label htmlFor={name}>{children}</label>
            <div className="input-container">
            <img src={image} alt="" />
            <span className="divider"></span>
            <input type={type} placeholder={placeholder} name={name} id='password' value={value} onChange={(e) => onChange(e.target.value)} />
            </div>
        </div>
    )
}

export default Input