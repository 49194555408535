import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Section from '../../components/Section';
import illustration1 from '../../assets/images/illustration/ranking/1.png'
import illustration2 from '../../assets/images/illustration/ranking/2.png'
import { motion } from 'framer-motion'
import rankingBg from '../../assets/images/background/rankingTable.png'
import rankingArrow from '../../assets/images/icon/rankingArrow.png'
import navigateArrow from '../../assets/images/icon/navigateArrow.png'

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100

const categoryMappings = {
  Reputation: { fieldName: 'reput', header: '名聲' },
  Level: { fieldName: 'level', header: '等級' },
  Family: { fieldName: 'level', header: '等級' },
  'Rainbow Battle Wins': { fieldName: 'lifetimeStats.totalRainbowBattleWon', header: '彩虹戰鬥勝利' },
  'Arena of Talent': { fieldName: 'arenaOfTalent', header: '天才競技場' },
  'Arena of Masters': { fieldName: 'arenaOfMasters', header: '大師競技場' },
};

const getNestedProperty = (obj, path) => {
  return path.split('.').reduce((o, p) => (o ? o[p] : undefined), obj);
};

const Ranking = ({ ranking }) => {
  const [page, setPage] = useState(0);
  const [actualCathegorie, setActualCathegorie] = useState('Reputation');
  const [rankings, setRankings] = useState({});
  const maxRows = 10;
  const cathegories = Object.keys(categoryMappings);

  useEffect(() => {
    setRankings(ranking);
  }, [ranking]);

  const getCurrentRanking = () => {
    switch (actualCathegorie) {
      case 'Reputation':
        return rankings.reputRanking || [];
      case 'Level':
        console.log(rankings.levelRanking);
        return rankings.levelRanking || [];
      case 'Family':
        return rankings.familyRanking || [];
      case 'Rainbow Battle Wins':
        return rankings.rainbowBattleRanking || [];
      case 'Arena of Talent':
        return [];
      case 'Arena of Masters':
        return [];
      default:
        return [];
    }
  };

  const numberOfPages = Math.floor(getCurrentRanking().length / maxRows);
  const currentMapping = categoryMappings[actualCathegorie];

  return (
    <Section id='ranking'>
      <motion.div
        initial={{ opacity: 0, y: translateY }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: translateY }}
        transition={transition}
        className="section-container image" style={{ backgroundImage: 'url(' + rankingBg + ')' }}>
        <div className="cathegories">
          {
            cathegories.map((cathegorie, key) => <div key={key} onClick={() => setActualCathegorie(cathegorie)} className={actualCathegorie === cathegorie ? "cathegorie active" : "cathegorie"}>{cathegorie}</div>)
          }
        </div>
        <div className="main-divider"></div>
        <Table sx={{ margin: "auto" }} className="table" >
          <TableHead>
            <TableRow>
              <TableCell><div className="flex">#<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
              <TableCell><div className="flex">名稱<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
              <TableCell><div className="flex">{currentMapping.header}<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCurrentRanking().slice(page * maxRows, (page + 1) * maxRows).map((row, key) => (
                <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{(key + 1) + (maxRows * page)}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{getNestedProperty(row, currentMapping.fieldName)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className="pages flex">
          <div className="back arrow" onClick={() => { if (page !== 0) { setPage(page - 1); console.log(page) } }}><img src={navigateArrow} alt='' /></div>
          {numberOfPages - 5 <= page && <span>...</span>}
          {numberOfPages - 5 > page ?
            [...Array(numberOfPages).keys()].slice(page, page + 5).map((pageNum, key) => <div key={key} onClick={() => setPage(pageNum)} className={pageNum === page ? "page active" : "page"}>{pageNum + 1}</div>)
            :
            [...Array(numberOfPages).keys()].slice(numberOfPages - 5, numberOfPages).map((pageNum, key) => <div key={key} onClick={() => setPage(pageNum)} className={pageNum === page ? "page active" : "page"}>{pageNum + 1}</div>)
          }
          {numberOfPages - 5 > page && <span>...</span>}

          <div onClick={() => setPage(numberOfPages)} className={numberOfPages === page ? "lastPage active" : "lastPage"}>{numberOfPages + 1}</div>
          <div className="forward arrow" onClick={() => { if (page < numberOfPages) { setPage(page + 1); console.log(page) } }}><img src={navigateArrow} alt='' style={{ transform: 'scale(-1, 1)' }} /></div>
        </div>
      </motion.div>
      <div className="flex illustrations-ranking">
        <motion.img
          initial={{ opacity: 0, x: -translateY }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -translateY }}
          transition={transition}
          className="illustration-1 first" src={illustration1} alt='' />
        <motion.img
          initial={{ opacity: 0, x: translateY }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: translateY }}
          transition={transition}
          className="illustration-2 second" src={illustration2} alt='' />
      </div>

    </Section>
  )
}

export default Ranking