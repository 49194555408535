import React, { useState, useContext } from 'react'
import Section from '../../components/Section'
import Roue from './components/Roue'
import Select from '../../components/Select'
import { AuthContext } from '../../context/AuthContext';
import { motion } from 'framer-motion'
import whellIllustration from '../../assets/images/illustration/whell.png'
const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100

const Whell = ({ character, items }) => {
  const { pity, setPity } = useContext(AuthContext);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const maxPity = 30;

  const selectOptions = character.map(c => ({
    value: c.id,
    text: c.name
  }));

  useState(() => {
    if (selectOptions.length > 0) {
      setSelectedCharacter(selectOptions[0]);
    }
  }, [character]);

  if (character.length === 0) {
    return (
      <Section id='whell'>
        <div className="whell-container">
          <motion.div
            initial={{ opacity: 0, y: -translateY }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -translateY }}
            transition={transition}
            style={{ zIndex: 1 }}
            className="section-container">
            <h1>幸運轉盤</h1>
            <div className="main-divider"></div>
            <p className='info'>沒有可用的角色。請添加角色以進行遊戲。</p>
          </motion.div>
          <motion.img 
            initial={{ opacity: 0, x: translateY }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: translateY }}
            transition={transition}
            className='illustration' src={whellIllustration} />
        </div>
      </Section>
    );
  }

  return (
    <Section id='whell'>
      <div className="whell-container">
        <motion.div
          initial={{ opacity: 0, y: translateY }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: translateY }}
          transition={transition}
          style={{ zIndex: 99999 }}
        >
          <Roue
            items={items}
            selectedCharacter={selectedCharacter}
            pityCounter={pity} 
            setPityCounter={setPity} 
            maxPity={maxPity}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -translateY }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -translateY }}
          transition={transition}
          style={{ zIndex: 1 }}
          className="section-container">
          <p className="info">
            憐憫機制: {pity} / {maxPity}
          </p>
          <h1>幸運轉盤</h1>
          <div className="main-divider"></div>
          <p className='info'>選擇你的角色</p>
          <Select handler={setSelectedCharacter} options={selectOptions}>{selectedCharacter?.text}</Select>
          <p className="info">
            <span className='warning'>警告 !</span><br />
            每次轉盤花費 {process.env.REACT_APP_WHEEL_TURN} 硬幣。
          </p>
        </motion.div>
      </div>
      <motion.img 
        initial={{ opacity: 0, x: translateY }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: translateY }}
        transition={transition}
        className='illustration' src={whellIllustration} />
    </Section>
  )
}

export default Whell