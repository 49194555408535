import React from 'react'
import Button from '../Button'
import { motion } from 'framer-motion'

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100

const Form = ({title, info="請正確填寫所有資料" , error, onSubmit, buttonTitle, children}) => {
  return (
    <motion.div 
    initial={{ opacity: 0, y: translateY }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: translateY }}
    transition={transition}
    className="form">
        <div className="form-container">
            <h1>{title}</h1>
            <div className="spacer"></div>
            <p className='error'>{error}</p>
            <div className="inputs">
              {children}
            </div>
            <Button handler={onSubmit} height='42px' width='148px' >{buttonTitle}</Button>
            <p className='info'>{info}</p>
        </div>
    </motion.div>
  )
}

export default Form