import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    const addToCart = (item) => {
        const existingItem = cartItems.find(cartItem => cartItem.vnum === item.vnum);
        
        if (existingItem) {
            setCartItems((prevItems) =>
                prevItems.map(cartItem =>
                    cartItem.vnum === item.vnum
                        ? { ...cartItem, quantity: cartItem.quantity + item.quantity }
                        : cartItem
                )
            );
        } else {
            const itemWithId = { ...item, uniqueId: Date.now() };
            setCartItems((prevItems) => [...prevItems, itemWithId]);
        }
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const removeFromCart = (uniqueId) => {
        setCartItems((prevItems) => prevItems.filter(item => item.uniqueId !== uniqueId));
    };

    const getTotalPrice = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, getTotalPrice, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};