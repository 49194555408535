import React, { useState } from 'react'
import Form from '../../components/Form'
import Input from '../../components/Input'
import Section from '../../components/Section/Section'
import emailIcon from '../../assets/images/icon/email.png'

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [isSuccess, setIsSuccess]  = useState(false)

  const handleSubmit = () => {
    if (!email) {
      setError("請輸入所有資料")
      return
    }
    setIsSuccess(true)
  }

  return (
    <Section id='forgot-password'>
      <Form title="忘記密碼" onSubmit={handleSubmit} buttonTitle="恢復" error={error} info="請輸入您的用戶名和電子郵件，我們將向您發送包含恢復指示的消息。">
        {isSuccess && <p className='success'>我們已經發送了一封電子郵件，請檢查您的垃圾郵件文件夾。</p>}
        <Input image={emailIcon} type='email' placeholder='電子郵件' name='email' value={email} onChange={setEmail} >電子郵件</Input>
      </Form>
    </Section>
  )
}

export default ForgotPassword
