import React, { useState, useRef, useEffect } from 'react'
import arrow from '../../assets/images/icon/arrow.png'

const Select = ({ handler, options, isSelectedInMenu=true, children, selector=true }) => {

    const [isSelecting, setIsSelecting] = useState(false)
    const [activeOption, setActiveOption] = useState('')
    const optionsRef = useRef(null)
    const arrowRef = useRef(null)

    const optionHandler = (value) => {
        handleIsSelecting()
        handler(value)
    }

    const handleIsSelecting = () => {
        if (optionsRef.current && arrowRef.current) {
            if (isSelecting) {
                optionsRef.current.style.transform = 'translateY(100px)'
                optionsRef.current.style.opacity = '0'
                setTimeout(() => {
                    setIsSelecting(false)
                }, 500)
            } else {
                optionsRef.current.style.transform = 'translateY(-100px)'
                optionsRef.current.style.opacity = '0'
                setIsSelecting(true)
                optionsRef.current.style.transform = 'translateY(10px)'
                optionsRef.current.style.opacity = '1'
            }
        }
    }

    useEffect(() => {

        if (!isSelectedInMenu) {
            optionsRef.current.style.transform = 'translateY(100px)'
            optionsRef.current.style.opacity = '0'
            setTimeout(() => {
                setIsSelecting(false)
            }, 500)
        }

    },[isSelectedInMenu])

    return (
        <div ref={arrowRef} className={`select ${isSelecting ? "active" : undefined}`}>
            <div  className="selected" onClick={() => handleIsSelecting()}>
                {children}
                {
                    selector &&
                    <div className="img-container">
                        <img className='arrow' src={arrow} alt="arrow" />
                    </div>
                }

            </div>
            <div className="options-container" ref={optionsRef}>
                {
                    isSelecting &&
                    <div className="options" >
                        {options.map(((option, key) => <div className={activeOption === key ? 'option active' : 'option'} onClick={() => {optionHandler(option); setActiveOption(key)}} key={key}>{option.text}</div>)) /* TODO: remplacer key par l'id de l'option */ } 
                    </div>
                }
            </div>

        </div>
    )
}

export default Select