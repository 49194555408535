import React from 'react'
import { Link } from 'react-router-dom';
import { mediumButtonBg } from '../../utils/data';

const Button = ({handler, type='button', height='fit', width='fit', fontSize='16.6px', children, disable=false, image=mediumButtonBg }) => {

  return (
    <>
    {
      type === 'link' ?
      <Link 
      to={handler} 
      className={`image custom-button ${disable && "disable"}`} 
      style={{backgroundImage: 'url('+image+')', height: height, width: width, fontSize: fontSize}}
      >
        {children}
      </Link>
      :
      <button 
      onClick={() => {handler(); window.scrollTo(0,0)}} 
      className={`image custom-button ${disable && "disable "}`} 
      style={{backgroundImage: 'url('+image+')', height: height, width: width, fontSize: fontSize}}
      >
        {children}
      </button>
    }
    </>
  )
}

export default Button