import React, { useContext, useState } from 'react';
import headerBg from '../../../../assets/images/background/shop-item.jpg';
import goldImg from '../../../../assets/images/icon/gold.png';
import Button from '../../../../components/Button';
import { CartContext } from '../../../../context/CartContext';

const ItemsCard = ({ item }) => {
  const { addToCart } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);

  let imageUrl;
  try {
    imageUrl = require(`../../../../assets/images/items/${item?.image}.png`);
  } catch (e) {
    imageUrl = '';
  }

  const handleAddToCart = () => {
    addToCart({ ...item, quantity });
  };

  const handleQuantityChange = (e) => {
    const value = Math.max(1, Math.min(999, Number(e.target.value)));
    setQuantity(value);
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + '...';
    }
    return description;
  };

  return (
    <div className="items-card">
      <div className="header image" style={{ backgroundImage: 'url(' + headerBg + ')' }}>
        <div className="img-container">
          <div className="img-item image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
        </div>
        <h4>{item?.name}</h4>
      </div>
      <div className="divider"></div>
      <div className="description">
        <div>
          <h5>描述</h5>
          <p>{truncateDescription(item?.description, 25)}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 'auto' }}>
          <label htmlFor={`quantity-${item?.vnum}`} style={{ marginRight: '10px' }}>數量:</label>
          <input 
            type="number" 
            id={`quantity-${item?.vnum}`} 
            name="quantity" 
            value={quantity}
            onChange={handleQuantityChange}
            min="1" 
            style={{ 
              width: '55px', 
              padding: '5px', 
              borderRadius: '4px', 
              border: '1px solid #0e0e0e', 
              textAlign: 'center', 
              fontFamily: 'inherit',
              fontSize: '14px',
              backgroundColor: '#1b1b1b',
              color: '#fff'
            }}
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div style={{ marginTop: 'auto', marginLeft: '6px', marginBottom: '3px' }} className="flex">
          <img src={goldImg} alt="gold" />
          <span className='price'>{item?.price} 金幣</span>
          <Button handler={handleAddToCart} height='20px' width='85px' fontSize='12.33px'>加入購物車</Button>
        </div>
      </div>
    </div>
  );
}

export default ItemsCard;
