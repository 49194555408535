import React, { useContext, useState } from 'react';
import { CartContext } from '../../context/CartContext';
import Button from '../../components/Button';
import goldImg from '../../assets/images/icon/gold.png';
import { motion } from 'framer-motion';
import Section from '../../components/Section/Section';
import Select from '../../components/Select';
import Scrollbar from '../../components/Scrollbar/Scrollbar';
import { AuthContext } from '../../context/AuthContext';

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100;

const CartPage = ({ character = [] }) => {
    const { processItemCheckout } = useContext(AuthContext);
    const { cartItems, removeFromCart, getTotalPrice, clearCart } = useContext(CartContext);
    const [successMessage, setSuccessMessage] = useState("");

    const selectOptions = character.map(c => ({
        value: c.id,
        text: c.name
    }));
    const [selectedCharacter, setSelectedCharacter] = useState(selectOptions[0] || null);

    const handleCheckout = async () => {
        const items = cartItems.map(item => ({
            vnum: item.vnum,
            price: item.price,
            quantity: item.quantity
        }));

        try {
            await processItemCheckout(getTotalPrice(), selectedCharacter, items);
            clearCart();
            setSuccessMessage("物品已發送到遊戲中。");
        } catch (error) {
            setSuccessMessage("未能將物品發送到遊戲中。");
        }
    };

    return (
        <Section id="cart">
            <motion.div
                initial={{ opacity: 0, y: -translateY }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -translateY }}
                transition={transition}
                style={{ zIndex: 1, width: '70%', margin: '0 auto' }}
                className="section-container"
            >
                <h1>您的購物車</h1>
                <div className="divider"></div>
                <Scrollbar height='497px'>
                    <div className="cart-items" style={{ display: 'flex', flexDirection: 'column' }}>
                        {cartItems.length === 0 ? (
                            <p>{ successMessage }</p>
                        ) : (
                            cartItems.map((item) => (
                                <div key={item.uniqueId} className="cart-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                    <div className="flex" style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                        <img src={require(`../../assets/images/items/${item.image}.png`)} alt={item.name} style={{ marginRight: '15px' }} />
                                        <div className="item-details" style={{ flex: 1 }}>
                                            <h4>{item.name} (x{item.quantity})</h4>
                                            <p>{item.description}</p>
                                            <div className="item-price" style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={goldImg} alt="gold" style={{ marginRight: '5px' }} />
                                                <span>{item.price} 金幣</span>
                                            </div>
                                            <br />
                                            <Button handler={() => removeFromCart(item.uniqueId)} height='20px' width='85px' fontSize='12.33px'>
                                                移除
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </Scrollbar>
                <div className="divider"></div>
                <div className="cart-total" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3>總價格: {getTotalPrice()} 金幣</h3>
                </div>
                <div className="select-character" style={{ marginTop: '20px' }}>
                    <h5>選擇您的角色</h5>
                    <Select handler={setSelectedCharacter} options={selectOptions}>{selectedCharacter?.text}</Select>
                </div>
                <Button height='30px' width='100px' fontSize='14px' style={{ marginTop: '20px' }} handler={handleCheckout}>
                    結帳
                </Button>
            </motion.div>
        </Section>
    );
};

export default CartPage;
