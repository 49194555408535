import React, { useState, useContext } from "react"
import Input from "../../components/Input"
import Form from "../../components/Form"
import Section from '../../components/Section/Section'
import couponRedeem from '../../assets/images/icon/coupon.png'
import { AuthContext } from '../../context/AuthContext';

const Coupon = () => {
    const { redeemCoins } = useContext(AuthContext);
    const [coupon, setCoupon] = useState("")
    const [error, setError] = useState("")

    const handleSubmit = async () => {
        if (!coupon) {
            setError("請輸入所有欄位");
            return;
        }

        try {
            await redeemCoins(coupon);
            setError("金幣已添加到您的帳戶");
        } catch (error) {
            setError("無效的兌換碼");
        }
    }

    return (
        <Section id='forgot-password'>
            <Form title="兌換碼兌換" info={"請正確輸入兌換碼進行兌換"} onSubmit={handleSubmit} buttonTitle="兌換" error={error} >
                <Input type='text' image={couponRedeem} placeholder='0123456789102030405060708090' name='coupon' value={coupon} onChange={setCoupon} >兌換碼</Input>
            </Form>
        </Section>
    )
}

export default Coupon
