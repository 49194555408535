import React from 'react'
import Section from '../../components/Section'

const Error = () => {
  return (
    <Section id={'notFund'}>
        <p>購買廢止</p>
    </Section>
  )
}

export default Error