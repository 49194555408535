import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import pp from '../../assets/images/icon/profile-picture.png';
import goldImg from '../../assets/images/icon/gold.png';
import Button from '../Button';
import { AuthContext } from '../../context/AuthContext';
import { CartContext } from '../../context/CartContext';

const Navbar = () => {
  const { user, name, logout, coins } = useContext(AuthContext);
  const { cartItems } = useContext(CartContext);
  const location = useLocation();
  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = () => {
    setShowSecondMenu(false);
    logout();
  };

  return (
    <nav className={showSecondMenu && user ? 'showSecondNav' : undefined}>

      <div className={showMenu ? "nav-container show" : "nav-container"} onClick={() => setShowSecondMenu(false)}>
        <ul>
          <li className={location.pathname === "/" ? "active" : undefined} onClick={() => setShowMenu(!showMenu)}><Link to={'/'}>首頁</Link></li>
          <span></span>
          <li className={location.pathname.includes("shop") ? "active" : undefined} onClick={() => setShowMenu(!showMenu)}><Link to={'/shop'}>商店</Link></li>
          <span></span>
          <li className={location.pathname.includes("news") ? "active" : undefined} onClick={() => setShowMenu(!showMenu)}><Link to={'/news'}>新聞</Link></li>
          <span></span>
          <li className={location.pathname.includes("downloads") ? "active" : undefined} onClick={() => setShowMenu(!showMenu)}><Link to={'/downloads'}>下載</Link></li>
          {user && (
            <>
              <span></span>
              <li className={location.pathname.includes("cart") ? "active" : undefined} onClick={() => setShowMenu(!showMenu)}><Link to={'/cart'}>購物車 ({ cartItems.length })</Link></li>
            </>
          )}
          <div className='auth-nav'>
            <Button handler="/signup" className='image' height='42px' width='148px' type='link'>立即註冊</Button>
            <Link onClick={() => setShowMenu(!showMenu)} to="/signin" className="button-light">登入</Link>
          </div>
        </ul>
      </div>

      <div className="menu-icon-container" onClick={() => setShowMenu(!showMenu)}>
        <div className={showMenu ? "menu-icon active" : "menu-icon"}></div>
      </div>

      {user ? (
        <div className="user">
          <div className="flex">
            <p className='welcome'>歡迎, <span className='info'>{name}</span> ({coins} <img style={{ width: '1.1em', height: '1.1em' }} src={goldImg} alt="金幣" />)</p>
            <div className="flex" onClick={() => setShowSecondMenu(!showSecondMenu)} >
              <div className="pp image" style={{ backgroundImage: 'url(' + pp + ')' }}></div>
              <div className={showSecondMenu ? "chevron active" : "chevron"}></div>
            </div>
          </div>

          {showSecondMenu && (
            <div className="secondNav">
              <ul onClick={() => { setShowSecondMenu(!showSecondMenu); setShowMenu(false); }}>
                <li className={location.pathname === "/user-panel" ? "active" : undefined}>
                  <Link to='/user-panel'>用戶面板</Link>
                </li>
                <li className={location.pathname === "/shop" ? "active" : undefined}>
                  <Link to='/shop'>商店</Link>
                </li>
                <li className={location.pathname === "/wheel" ? "active" : undefined}>
                  <Link to='/wheel'>輪盤</Link>
                </li>
                <li className={location.pathname === "/coupon" ? "active" : undefined}>
                  <Link to='/coupon'>優惠券兌換</Link>
                </li>
                <li className={location.pathname === "/buy-coins" ? "active" : undefined}>
                  <Link to='/buy-coins'>購買金幣</Link>
                </li>
              </ul>
              <div className="main-divider"></div>
              <button style={{ cursor: 'pointer' }} onClick={handleLogout}>登出</button>
            </div>
          )}
        </div>
      ) : (
        <div className='auth'>
          <Button handler="/signup" className='image' height='42px' width='148px' type='link'>立即註冊</Button>
          <span>或</span>
          <Link to="/signin" className="button-light">登入</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
