import React, { useState } from 'react';
import Select from '../../../../components/Select/Select';

const Filters = ({ categories, onFilterChange }) => {
    const [activeMenu, setActiveMenu] = useState("");

    const options = categories.map(category => ({
        title: category.name,
        options: category.subCategories.map(subCategory => ({
            value: subCategory.id,
            text: subCategory.name
        }))
    }));

    const handleOptionChange = (selectedOptions) => {
        onFilterChange(selectedOptions);
    };

    return (
        <div id="filters">
            <div className="filter-container section-container">
                <ul className='flex'>
                    {options.map((option, key) => (
                        <div
                            className={option.title === activeMenu ? "activeMenu" : ""}
                            key={key}
                            onClick={() => setActiveMenu(option.title)}
                        >
                            <Select
                                selector={false}
                                isSelectedInMenu={option.title === activeMenu}
                                handler={handleOptionChange}
                                options={option.options}
                            >
                                {option.title}
                            </Select>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Filters;
