const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export async function buyCoins(orderId, transactionDetails, purchasedCoins, user) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': apiKey },
            body: JSON.stringify({ "OrderID": orderId, "TransactionDetails": transactionDetails, "PurchasedCoins": purchasedCoins, "JwtToken": user }),
        };

        const response = await fetch(`${apiUrl}shop/paypal-transaction-complete`, requestOptions);

        if (!response.ok) {
            return "";
        }

        const data = response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export async function fetchCharacters(user) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': apiKey },
            body: JSON.stringify({ "JwtToken": user }),
        };

        const response = await fetch(`${apiUrl}user/characters`, requestOptions);

        if (!response.ok) {
            return "";
        }

        const data = response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export async function fetchCoins() {
    try {
        const response = await fetch(`${apiUrl}shop/coins`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export async function fetchArticles() {
    try {
        const response = await fetch(`${apiUrl}news/articles`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export async function fetchItems() {
    try {
        const response = await fetch(`${apiUrl}shop/items`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export async function fetchWheelItems() {
    try {
        const response = await fetch(`${apiUrl}shop/wheel`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export async function fetchCategories() {
    try {
        const response = await fetch(`${apiUrl}shop/categories`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}

export async function addFps(user, phoneNumber) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': apiKey },
            body: JSON.stringify({ "JwtToken": user, "PhoneNumber": phoneNumber }),
        };

        const response = await fetch(`${apiUrl}shop/fps`, requestOptions);

        if (!response.ok) {
            return "";
        }

        return "ok";
    } catch (error) {
        return [];
    }
}

export async function getStatus() {
    try {
        const response = await fetch(`${apiUrl}user/status`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "no";
        }

        return "ok";
    } catch (error) {
        return "no";
    }
}

export async function getCaptcha() {
    try {
        const response = await fetch(`${apiUrl}user/generate`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        return "no";
    }
}

export async function getRanking() {
    try {
        const response = await fetch(`${apiUrl}user/ranking`, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return "no";
    }
}

export async function getShopHistory(user) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': apiKey },
            body: JSON.stringify({ "JwtToken": user }),
        };

        const response = await fetch(`${apiUrl}user/shophistory`, requestOptions);

        if (!response.ok) {
            return "";
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return [];
    }
}
