import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [name, setName] = useState(null);
    const [coins, setCoins] = useState(null);
    const [pity, setPity] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const apiKey = process.env.REACT_APP_API_KEY;
            const storedUser = localStorage.getItem('user');
                
            if (!storedUser) {
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}user/loginToken`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ JwtToken: storedUser }),
            });

            if (!response.ok) {
                return;
            }

            const data = await response.json();
            localStorage.setItem('user', data.token);
            setUser(data.token);
            setName(data.name);
            setCoins(data.coins);
            setPity(data.pity);
        };

        fetchData();
    }, []);

    const stripe = async (amount) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await fetch(`${process.env.REACT_APP_API_URL}shop/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "JwtToken": user, "Amount": amount }),
            });

            if (!response.ok) {
                return;
            }
        } catch (error) {
            throw new Error(error.message)
        }
    };

    const register = async (username, password, email, captchaToken) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await fetch(`${process.env.REACT_APP_API_URL}user/register`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password, email, Token: captchaToken }),
            });

            if (!response.ok) {
                return;
            }

            const data = await response.json();
            localStorage.setItem('user', data.token);
            setUser(data.token);
            setName(data.name);
            setCoins(data.coins);
            setPity(0);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const login = async (username, password) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await fetch(`${process.env.REACT_APP_API_URL}user/login`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                return;
            }

            const data = await response.json();
            localStorage.setItem('user', data.token);
            setUser(data.token);
            setName(data.name);
            setCoins(data.coins);
            setPity(data.pity);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const resetPassword = async (oldPassword, newPassword) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await fetch(`${process.env.REACT_APP_API_URL}user/resetpassword`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "JwtToken": user, "Password": oldPassword, "NewPassword": newPassword }),
            });

            if (!response.ok) {
                return;
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const resetEmail = async (email, newEmail) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await fetch(`${process.env.REACT_APP_API_URL}user/resetemail`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "JwtToken": user, "Email": email, "NewEmail": newEmail }),
            });

            if (!response.ok) {
                return;
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const processWheelWin = async (price, selectedCharacter, item) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const { vnum, quantity } = item;
            const simplifiedItem = { vnum, quantity };

            console.log([simplifiedItem]);

            const response = await fetch(`${process.env.REACT_APP_API_URL}player/gift/id/${selectedCharacter.value}`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "SenderName": "ElementalNos", "GiftsType": 2, "Gifts": [simplifiedItem], "JwtToken": user }),
            });

            if (!response.ok) {
                throw new Error('發送獎品失敗');
            }

            setCoins(prevCoins => prevCoins - price);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const processItemCheckout = async (price, selectedCharacter, item) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const response = await fetch(`${process.env.REACT_APP_API_URL}player/gift/id/${selectedCharacter.value}`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "SenderName": "ElementalNos", "GiftsType": 1, "Gifts": item, "JwtToken": user }),
            });

            if (!response.ok) {
                throw new Error('發送物品失敗');
            }

            setCoins(prevCoins => prevCoins - price);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const redeemCoins = async (coupon) => {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'x-api-key': apiKey },
                body: JSON.stringify({ "JwtToken": user, "Coupon": coupon }),
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}shop/redeem`, requestOptions);

            if (!response.ok) {
                throw new Error('兌換優惠券失敗');
            }

            const data = await response.json();
            setCoins(data);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const logout = () => {
        localStorage.removeItem('user');
        setUser(null);
        setName(null);
    };

    return (
        <AuthContext.Provider value={{ user, name, coins, pity, setPity, login, logout, register, resetPassword, resetEmail, processWheelWin, redeemCoins, processItemCheckout, stripe }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
