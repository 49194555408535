import React from 'react'
import { motion } from 'framer-motion'
const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <motion.footer 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={transition}
    className='flex'>
        <p>@ {currentYear} ElementalNos. 版權所有。所有商標均為其各自所有者的財產。</p>
    </motion.footer>
  )
}

export default Footer
