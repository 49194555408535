import React, {useState} from 'react'
import authBg from '../../assets/images/background/main.jpg'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom';

const transition = { duration: 1.5, ease: [0.83, 0, 0.16, 0.99] };
const transition2 = { duration: 1.5 };


const Section = ({ id, children }) => {
  const location = useLocation()

  useState(() => {
      window.scrollTo(0,0)
  }, [location])

  return (
    <div>
      <section
        id={id}
        className='section'
      >
        <motion.div
          initial={{ opacity: 0 }}
          transition={transition2}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="section-bg-img image" style={{ backgroundImage: 'url(' + authBg + ')' }}></motion.div>
        <div className="section-content">
          {children}
        </div>
      </section>

      <motion.div
        initial={{ y: '100%' }}
        transition={transition}
        animate={{ y: '100%' }}
        exit={{ y: 0 }}
        className="section-transition"></motion.div>
    </div>
  )
}

export default Section