import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Form from '../../components/Form'
import Input from '../../components/Input'
import Section from '../../components/Section/Section'
import passwordIcon from '../../assets/images/icon/password.png'
import emailIcon from '../../assets/images/icon/email.png'
import userIcon from '../../assets/images/icon/user.png'
import { AuthContext } from '../../context/AuthContext';

const SignUp = () => {
  const { register } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!(username || email || password || confirmPassword)) {
      setError("請輸入所有資料...");
      return;
    }

    if (password !== confirmPassword) {
      setError("密碼和確認密碼不一致...");
      return;
    }

    if (!captchaToken) {
      setError("請完成驗證碼驗證");
      return;
    }

    try {
      await register(username, password, email, captchaToken);
      navigate('/');
    } catch (error) {
        setError(error.message);
    }
  }

  const handleCaptchaVerification = (token) => {
    setCaptchaToken(token);
  };

  return (
    <Section id='signup'>
      <Form title="註冊" onSubmit={handleSubmit} buttonTitle="註冊" error={error}>
        <Input image={userIcon} type='text' placeholder='用戶名#0001' name='username' value={username} onChange={setUsername} >用戶名</Input>
        <Input image={passwordIcon} type='password' placeholder='密碼' name='password' value={password} onChange={setPassword} >密碼</Input>
        <Input image={passwordIcon} type='password' placeholder='確認密碼' name='confirmPassword' value={confirmPassword} onChange={setConfirmPassword} >確認密碼</Input>
        <Input image={emailIcon} type='email' placeholder='電子郵件' name='email' value={email} onChange={setEmail} >電子郵件</Input>
        <div className="captcha">
          <p>請輸入下方圖片中的文字：</p>
          <HCaptcha
            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
            onVerify={handleCaptchaVerification}
          />
        </div>
      </Form>
    </Section>
  )
}

export default SignUp