import React from 'react';
import Section from '../../components/Section/Section';
import { useNavigate } from 'react-router-dom';
import lastNewsImg from '../../assets/images/illustration/last-news.png';
import Button from '../../components/Button';
import { motion } from 'framer-motion';
import ScrollBar from '../../components/Scrollbar/Scrollbar';

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100;

const formattedDate = (dateString) => {
    if (!dateString) return '';

    try {
        const date = new Date(dateString);
        if (isNaN(date)) throw new Error('日期格式無效');

        return date.toLocaleDateString('zh-TW', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    } catch (error) {
        console.error('格式化日期時出錯:', error);
        return '';
    }
};

const ArticleCard = ({ article }) => (
    <div className="articleCard">
        <div className="image" style={{ backgroundImage: 'url(' + article?.image + ')' }} ></div>

        <div className="text">
            <div className="flex">
                <span className='date'>{formattedDate(article?.date)}</span>
                <span className='divider'>|</span>
                <p className='author'>由 <span>{article?.author}</span></p>
            </div>
            <p className='description'>{article?.description}</p>
        </div>
    </div>
);

const News = ({ articles = [] }) => {
    const navigate = useNavigate();
    const lastNews = articles[articles.length - 1];

    return (
        <section id='news'>
            <Section>
                {articles.length > 0 ? (
                    <>
                        <motion.div
                            initial={{ opacity: 0, y: translateY }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: translateY }}
                            transition={transition}
                            className="lastNews">
                            <h1>最新消息</h1>
                            <div className="flex header">
                                <div className="author"><span>由</span> {lastNews?.author}</div>
                                <div className="divider">|</div>
                                <span className='date'>{formattedDate(lastNews?.date)}</span>
                            </div>
                            <h2>{lastNews?.title}</h2>
                            <p className="paragraph">{lastNews?.paragraphs[0].slice(0, 350)}...</p>
                            <Button handler={'/news/' + lastNews.id} type='link' width='148px' height='43px'>了解更多</Button>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: translateY }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: translateY }}
                            transition={transition}
                            className="lastNews-img image" style={{ backgroundImage: 'url(' + lastNewsImg + ')' }} alt='' ></motion.div>
                        <div className="big-divider"></div>
                    </>
                ) : (
                    <Section id='whell'>
                        <div className="whell-container">
                        <motion.div
                            initial={{ opacity: 0, y: -translateY }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -translateY }}
                            transition={transition}
                            style={{ zIndex: 1 }}
                            className="section-container">
                            <h1>新聞</h1>
                            <div className="main-divider"></div>
                            <p className='info'>目前沒有可用的新聞。</p>
                        </motion.div>
                        </div>
                    </Section>
                )}
            </Section>

            {articles.length > 0 && (
                <div className="articles">
                    <ScrollBar height='100%' width='100%'>
                        <div className="articles-container">
                            {articles.map((article, key) => (
                                <motion.div
                                    initial={{ opacity: 0, y: translateY }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: translateY }}
                                    transition={{ ...transition, delay: 0.1 * key }}
                                    key={key} onClick={() => navigate('/news/' + article.id)}>
                                    <ArticleCard article={article} key={key} />
                                </motion.div>
                            ))}
                        </div>
                    </ScrollBar>
                </div>
            )}
        </section>
    );
};

export default News;
