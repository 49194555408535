import React from 'react';
import FreeScrollBar from 'react-free-scrollbar';

const ScrollBar = ({width='100%', height='100%', direction='vertical', children}) => {
  return (
    <FreeScrollBar
    autohide={false}
    style={{width: width, height: height}}
    className='demo'
    >
        {children}
    </FreeScrollBar>
  )
}

export default ScrollBar