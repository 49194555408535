import React, { useContext, useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ScrollBar from '../../../../components/Scrollbar/Scrollbar';
import { getShopHistory } from '../../../../utils/api';
import { AuthContext } from '../../../../context/AuthContext';

const ShopHistory = () => {
  const { user } = useContext(AuthContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchShopHistory = async () => {
      try {
        const data = await getShopHistory(user);
        setItems(data.shopHistory);
      } catch (error) {
        console.error('Error fetching shop history:', error);
      }
    };

    fetchShopHistory();
  }, [user]);

  return (
    <div className="section-container shopHistory custom-scrollbar">
      <h2>SHOP HISTORY</h2>
      <div className="main-divider"></div>

      <ScrollBar height='165px'>
      <div className="table-container">
      <Table sx={{ maxWidth: 700, margin: "auto" }} className="table">
        <TableHead>
          <TableRow>
            <TableCell style={{textAlign: 'left'}}>#</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, key) => {
                let imageUrl;
                try {
                  imageUrl = require(`../../../../assets/images/items/${row.image}.png`);
                } catch (e) {
                  imageUrl = '';
                }
                return (
                  <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell style={{ textAlign: 'left' }}>{key + 1}</TableCell>
                    <TableCell><img src={imageUrl} alt='' /></TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.date}</TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
      </div>
      </ScrollBar>
    </div>
  )
}

export default ShopHistory