import React from 'react'
import Section from '../../components/Section'

const Success = () => {
  return (
    <Section id={'notFund'}>
        <p>成功購買硬幣</p>
    </Section>
  )
}

export default Success