import React from 'react'
import Section from '../components/Section'

const NotFound = () => {
  return (
    <Section id={'notFund'}>
        <p>404</p>
      </Section>
  )
}

export default NotFound