import { useState, useEffect } from 'react'

const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
            const elm = element
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (elm.current) {
                        setState(entry.isIntersecting);
                        console.log("hi");
                    } else {
                        console.log("hey");
                    }
                }, { rootMargin }
            );
    
            elm.current && observer.observe(elm.current);
    
            return () => elm.current && observer.unobserve(elm.current);

    }, [element, rootMargin]);

    return isVisible;
};

export default useIntersection