import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/404";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import SignIn from "./pages/Authentication/SignIn";
import SignUp from "./pages/Authentication/SignUp";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import Coupon from "./pages/Coupon";
import Shop from "./pages/Shop";
import Download from "./pages/Download";
import BuyCoins from "./pages/BuyCoins";
import News from "./pages/News";
import Article from './pages/Article'
import Whell from "./pages/Whell";
import UserPanel from "./pages/UserPanel";
import CartPage from "./pages/Cart";
import { AnimatePresence } from "framer-motion";
import Ranking from "./pages/Ranking";
import { fetchArticles, fetchCoins, fetchItems, fetchCharacters, fetchWheelItems, fetchCategories, getRanking } from "./utils/api";
import ProtectedRoute from './ProtectedRoute';
import { AuthContext } from './context/AuthContext';
import Success from './pages/Success'
import Cancel from './pages/Cancel'

const App = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation()

    const [articles, setArticles] = useState([]);
    const [coins, setCoins] = useState([]);
    const [items, setItems] = useState([]);
    const [characters, setCharacters] = useState([]);
    const [wheelItems, setWheelItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [rankingDatas, setRankingDatas] = useState([]);

    useEffect(() => {
        const fetchArticlesData = async () => {
            const articlesData = await fetchArticles();
            setArticles(articlesData);
        };

        const fetchCoinsData = async () => {
            const coinsData = await fetchCoins();
            setCoins(coinsData);
        };

        const fetchItemsData = async() => {
            const itemsData = await fetchItems();
            setItems(itemsData);
        }

        const fetchCharactersData = async() => {
            const charactersData = await fetchCharacters(user);
            setCharacters(charactersData);
        }

        const fetchWheelItemsData = async() => {
            const wheelItemsData = await fetchWheelItems();
            setWheelItems(wheelItemsData);
        }

        const fetchCategoriesData = async() => {
            const categoriesData = await fetchCategories();
            setCategories(categoriesData);
        }

        const fetchRankingData = async() => {
            const rankingDatas = await getRanking();
            setRankingDatas(rankingDatas);
        }

        fetchArticlesData();
        fetchCoinsData();
        fetchItemsData();
        fetchCharactersData();
        fetchWheelItemsData();
        fetchCategoriesData();
        fetchRankingData();
    }, [user]);

    return (
        <div className="App">
            <Navbar />
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home ranking={rankingDatas} />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/shop" element={<Shop items={items} character={characters} categories={categories} />} />
                    </Route>
                    <Route element={<ProtectedRoute />}>
                        <Route path="/cart" element={<CartPage character={characters} />} />
                    </Route>
                    <Route path="/downloads" element={<Download />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/buy-coins" element={<BuyCoins coins={coins} />} />
                    </Route>
                    <Route path="/news" element={<News articles={articles} />} />
                    <Route path="/news/:id" element={<Article articles={articles} />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/wheel" element={<Whell character={characters} items={wheelItems} />} />
                    </Route>
                    <Route path="/ranking" element={<Ranking ranking={rankingDatas} />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/coupon" element={<Coupon />} />
                    </Route>
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/user-panel" element={<UserPanel />} />
                    </Route>
                    <Route path="/success" element={<Success />} />
                    <Route path="/cancel" element={<Cancel />} />
                    <Route path="*" element={<NotFound status={404} />} />
                </Routes>
            </ AnimatePresence>
            {(!location.pathname.includes("/news/:id")) && <Footer />}
        </div>
    );
}

export default App;
