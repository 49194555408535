import React from 'react'

const steps = [
    {
        numero: 1,
        title: "下載完整客戶端",
        description: "將客戶端解壓縮到您想要的位置"
    },
    {
        numero: 2,
        title: "運行啟動器",
        description: "啟動啟動器並等待更新完成。"
    },
    {
        numero: 3,
        title: "準備好開始遊戲！",
        description: "您現在可以開始遊戲了！從啟動器進入遊戲"
    }
]

const Step = ({numero, title, description, isDivider=true}) => (
    <>
        <div className="step">
        <div className="step-container">
            <p className='num'>{numero}</p>
            <div className="text">
                <p className="title">{title}</p>
                <p className="description">{description}</p>
            </div>
        </div>
    </div>
    {isDivider && <div className="divider"></div>}
    </>

)

const Steps = () => {
  return (
    <div className='steps'>
        <div className="flex section-container steps-container">
            {
                steps.map((step, key) => <Step numero={step.numero} title={step.title} description={step.description} key={key} isDivider={step.numero !== steps.length} />)
            }
        </div>
    </div>
  )
}

export default Steps
