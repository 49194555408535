import React, { useState, useContext } from 'react'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Section from '../../components/Section'
import ShopHistory from './components/ShopHistory'
import { motion } from 'framer-motion'
import lockIcon from '../../assets/images/icon/password.png'
import emailIcon from '../../assets/images/icon/email.png'
import { AuthContext } from '../../context/AuthContext';

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100

const UserPanel = () => {
    const { resetPassword, resetEmail } = useContext(AuthContext);

    // Email
    const [email, setEmail] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [confirmEmail, setConfirmEmail] = useState("")
    const [emailError, setEmailError] = useState("")

    const handleSaveEmail = async () => {
        setEmailError('')
        if (!(email || newEmail || confirmEmail)) {
            setEmailError("請輸入所有欄位...")
        }
        if (newEmail !== confirmEmail) {
            setEmailError("請輸入相同的新電子郵件...")
        }

        try {
            await resetEmail(email, newEmail);
            setEmailError("電子郵件已更改！")
        } catch (error) {
            setEmailError(error.message);
        }
    }

    // Password
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")

    const handleSavePassword = async () => {
        setPasswordError('')
        if (!(password && newPassword && confirmPassword)) {
            setPasswordError("請輸入所有欄位...")
            return
        }
        if (newPassword !== confirmPassword) {
            setPasswordError("請輸入相同的新密碼...")
            return
        }

        try {
            await resetPassword(password, newPassword);
            setPasswordError("密碼已更改！")
        } catch (error) {
            setPasswordError(error.message);
        }
    }

    return (
        <Section id="userPanel">
            <motion.div
                initial={{ opacity: 0, y: translateY }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: translateY }}
                transition={transition}
                className="left">
                <div className="section-container change-user-info">
                    <div className="flex header">
                        <h2>更改密碼</h2>
                        <Button handler={handleSavePassword} height='37px' width='170px' fontSize='14.3px'>更改密碼</Button>
                    </div>
                    <div className="main-divider"></div>
                    {passwordError && <p className='error'>{passwordError}</p>}
                    <div className="flex inputs">
                        <Input name="當前密碼" image={lockIcon} type='password' onChange={setPassword} placeholder="***********"  value={password}>當前密碼</Input>
                        <Input name="新密碼" image={lockIcon} type='password' onChange={setNewPassword} placeholder="***********" value={newPassword}>新密碼</Input>
                        <Input name="確認密碼" image={lockIcon} type='password' onChange={setConfirmPassword} placeholder="***********" value={confirmPassword}>確認密碼</Input>
                    </div>
                </div>

                <div className="section-container change-user-info">
                    <div className="flex header">
                        <h2>更改電子郵件</h2>
                        <Button handler={handleSaveEmail} height='37px' width='170px' fontSize='14.3px'>更改電子郵件</Button>
                    </div>
                    <div className="main-divider"></div>
                    {emailError && <p className='error'>{emailError}</p>}
                    <div className="flex inputs">
                        <Input name="當前電子郵件" image={emailIcon} type='email' onChange={setEmail} placeholder="useremail@sample.com" value={email}>當前電子郵件</Input>
                        <Input name="新電子郵件" image={emailIcon} type='email' onChange={setNewEmail} placeholder="useremail@sample.com" value={newEmail}>新電子郵件</Input>
                        <Input name="確認電子郵件" image={emailIcon} type='email' onChange={setConfirmEmail} placeholder="useremail@sample.com" value={confirmEmail}>確認電子郵件</Input>
                    </div>
                </div>

                <ShopHistory />
            </motion.div>
        </Section>
    )
}

export default UserPanel
