import React from 'react'
import Steps from './Steps'
import Section from '../../components/Section/Section'
import Button from '../../components/Button'
import downloadIcon from '../../assets/images/icon/download.png'
import { motion } from 'framer-motion'
import { bigButtonBg } from '../../utils/data'

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100

const Download = () => {
  return (
    <Section id='download'>

      <motion.div
        initial={{ opacity: 0, y: -translateY }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -translateY }}
        transition={transition}>
        <Steps />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: translateY }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: translateY }}
        transition={transition}
        className="downloads">
        <h4>可供下載：</h4>
        <Button image={bigButtonBg} type="link" handler="https://www.dropbox.com/scl/fi/w6fe0rca33sdjfdzwfnpq/ElementalNos-Launcher.rar?rlkey=74m52qdphy0lukt34441896vz&st=39bhxh0b&dl=1"><img src={downloadIcon} alt='' /> 下載 Setup.exe</Button>
        <Button image={bigButtonBg} disable={true} ><img src={downloadIcon} alt='' /> 暫不可用</Button>
        <Button image={bigButtonBg} disable={true} ><img src={downloadIcon} alt='' /> 暫不可用</Button>
      </motion.div>

      <motion.div
              initial={{ opacity: 0, y: translateY }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: translateY }}
              transition={{...transition, delay: 0.2}}
      >
      <h5>系統需求</h5>
      <div className="paragraphs">
        <div className="section-container">
          最低要求：<br />
          作業系統: Windows Vista, Windows 7, Windows 8, Windows 10<br />
          處理器: Pentium 3 800 MHz<br />
          記憶體: 512 MB RAM<br />
          顯示卡: GeForce 2 MX<br />
          DirectX: 版本 9.0<br />
          網路: 寬頻網際網路連線<br />
          儲存空間: 6 GB 可用空間<br />
        </div>
        <div className="section-container mt">
          推薦配置：<br />
          作業系統: Windows 7, Windows 8, Windows 10<br />
          處理器: Intel i3<br />
          記憶體: 2 GB RAM<br />
          顯示卡: GTS 450<br />
          DirectX: 版本 9.0<br />
          網路: 寬頻網際網路連線<br />
          儲存空間: 8 GB 可用空間<br />
        </div>
      </div>
      </motion.div>

    </Section>
  )
}

export default Download
