import React, { useRef, useState } from 'react'
import homeBg from '../../assets/images/background/home.jpg'
import logo from '../../assets/images/logo/logo.png'
import illustration3 from '../../assets/images/illustration/illustration-3.png'
import illustration4 from '../../assets/images/illustration/illustration-4.png'
import rankingBg from '../../assets/images/background/ranking.png'
import download from '../../assets/images/icon/download.png'
import RankingTable from './components/RankingTable'
import Button from '../../components/Button'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import discrodIcon from '../../assets/images/icon/home-dc.svg'
import facebookIcon from '../../assets/images/icon/home-fb.svg'
import discord from '../../assets/images/icon/discord.png'
import youtube from '../../assets/images/icon/youtube.png'
import footerImg from '../../assets/images/background/footer.png'
import useIntersection from '../../utils/useItersection'
import { bigButtonBg } from '../../utils/data'

const transition = { duration: 1.5, ease: [0.83, 0, 0.16, 0.99] };

const Home = ({ ranking }) => {
  const navigation = useNavigate()
  const RankingpRef = useRef()
  const FooterpRef = useRef()
  const RankingInViewport = useIntersection(RankingpRef, '0px')
  const FooterInViewport = useIntersection(FooterpRef, '0px')
  const [serverStatus] = useState('檢查中...');

  if (RankingInViewport) {
    console.log(RankingpRef.current);
  }

  const statusColor = serverStatus === '在線' ? '#41b561' : serverStatus === '離線' ? 'red' : 'inherit';

  return (
    <section id='home' >
      <motion.div
        initial={{ y: 0 }}
        transition={transition}
        animate={{ y: '100%' }}
        exit={{ y: 0 }}
        className="section-transition"></motion.div>
        
      <header >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={transition}
          className="header-img image" style={{ backgroundImage: 'url(' + homeBg + ')' }}></motion.div>
        <div className="header-container">
          <motion.div
            initial={{ y: 100 }}
            transition={transition}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            className="logo image" style={{ backgroundImage: 'url(' + logo + ')' }}></motion.div>
          <motion.p
            initial={{ y: 100 }}
            transition={{ ...transition, delay: 0.1 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            className='subtitle'>免費遊玩的動漫動作 MMORPG NosTale 現在可以與 ElementalNos 一起體驗。提供高倍率、重製的官方和自訂內容。<br />你的冒險現在開始！</motion.p>
          <motion.div
            initial={{ y: 100 }}
            transition={{ ...transition, delay: 0.3 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
          >
            <Button image={bigButtonBg} handler={() => navigation('/downloads')}><img src={download} alt="下載圖示" /> 下載 Setup.exe</Button>
          </motion.div>
        </div>

        <div className="serverStatus">伺服器狀態: <span style={{ color: statusColor }}>{ serverStatus }</span></div>

        <a href='#home-news' className="scrollDown">
          <div className="arrow"></div>
        </a>

        <div className="social-icons">
          <motion.a
            initial={{ x: 100 }}
            transition={transition}
            animate={{ x: 0 }}
            exit={{ x: 100 }}
            style={{ backgroundImage: 'url(' + discrodIcon + ')' }}
            href="#" className='image'>
          </motion.a>
          <motion.a
            initial={{ x: 100 }}
            transition={{ ...transition, delay: 0.1 }}
            animate={{ x: 0 }}
            exit={{ x: 100 }}
            style={{ backgroundImage: 'url(' + facebookIcon + ')' }}
            href="#" className='image'>
          </motion.a>


        </div>
      </header>

      <section id='homeRanking' ref={RankingpRef} className='image' style={{ backgroundImage: 'url(' + rankingBg + ')'}}>
        <h2>前 10 名玩家</h2>

        <RankingTable rankingDatas={ranking} />

        <div className="illustrations">
          <div className="flex">
            <div className="img-container">
              <img className='illustration big' src={illustration3} alt="elementalnos 插圖" />
            </div>
            <div className="img-container">
              <img className='illustration big' src={illustration4} alt="elementalnos 插圖" style={{ transform: 'translateX(30%)' }} />
            </div>
          </div>
        </div>
      </section>

      <div className={`scrollTop ${(RankingInViewport || FooterInViewport) && "show"}`} onClick={() => window.scrollTo(0, 0)}>
        <div className="arrow"></div>
      </div>

      <div className="socialMediaFooter image" style={{backgroundImage: 'url('+footerImg+')'}}>
        <h2>加入我們的社群</h2>
        <p className='subtitle'>成為充滿像你一樣酷玩家的社群的一部分！</p>
        <div className="flex links">
          <a href='https://www.youtube.com/@KElementalNos' className="flex yt">
            <img src={youtube} alt="discord 圖示" />
            <div className="text">
              <p className="logo">YOUTUBE</p>
              <p>@ElementalNosYT</p>
            </div>
          </a>
          <a href='https://discord.gg/dFpQM98fTr' className="flex dc">
            <img src={discord} alt="discord 圖示" />
            <div className="text">
              <p className='logo'>DISCORD</p>
              <p>/ElementalNos</p>
            </div>
          </a>
        </div>
      </div>

    </section>
  )
}

export default Home
