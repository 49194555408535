import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Section from '../../components/Section/Section'
import Button from '../../components/Button'
import { motion } from 'framer-motion'
import illustartion1 from '../../assets/images/illustration/news/1.png'
import illustartion2 from '../../assets/images/illustration/news/2.png'
import { smallButtonBg } from '../../utils/data'
const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100

const formattedDate = (dateString) => {
  if (!dateString) return '';

  try {
      const date = new Date(dateString);
      if (isNaN(date)) throw new Error('Invalid date format');

      return date.toLocaleDateString('zh-TW', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
      });
  } catch (error) {
      console.error('Error formatting date:', error);
      return '';
  }
};

const Article = ({ articles }) => {

  const navigate = useNavigate()
  const params = useParams()
  let foundArticle;

  for (const item of articles) {
    if (item.id === parseInt(params.id)) {
      foundArticle = item;
      break;
    }
  }
  
  const article = foundArticle;
  return (
    <Section id='article'>
      <motion.div
        initial={{ y: translateY }}
        transition={transition}
        animate={{ y: 0 }}
        exit={{ y: translateY }}
        className='section-container'>
        <h1>{article?.title}</h1>

        <div className="flex authorDate">
          <div className="author"><span>作者: </span>{article?.author}</div>
          <div className="divider"></div>
          <span className='date'>{formattedDate(article?.date)}</span>
        </div>

        <img src={article?.image} alt="" />

        <div className="paragraphs">
          {article?.paragraphs.map((paragraph, key) => <p className='paragraph' key={key}>{paragraph}<br /><br /></p>)}
        </div>

        <Button handler={() => navigate('/news')} height='38px' width='111px' image={smallButtonBg}>其他新聞</Button>
      </motion.div>
      <div className="illustrations-news flex">
        <motion.div
          initial={{ x: -translateY, opacity: 0 }}
          transition={transition}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -translateY, opacity: 0 }}
          className="illustration-news-1 image" style={{ backgroundImage: 'url(' + illustartion1 + ')' }}></motion.div>
        <motion.div
          initial={{ x: translateY, opacity: 0 }}
          transition={transition}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: translateY, opacity: 0 }}
          className="illustration-news-2 image" style={{ backgroundImage: 'url(' + illustartion2 + ')' }}></motion.div>
      </div>
      <div className="big-divider"></div>
    </Section>
  )
}

export default Article
