import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '../../../../components/Button';
import RankingTableImg from '../../../../assets/images/background/rankingTable.png';
import { smallButtonBg } from '../../../../utils/data';
import rankingArrow from '../../../../assets/images/icon/rankingArrow.png'

const RankingTable = ({ rankingDatas }) => {
    const bestPlayersRanking = rankingDatas?.bestPlayersRanking || [];

    return (
        <section id='ranking-table' className='image' style={{backgroundImage: 'url('+RankingTableImg+')'}}>
            <Table sx={{ maxWidth: 700, margin: "auto" }} className="table">
                <TableHead>
                    <TableRow>
                        <TableCell><div className="flex">#<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
                        <TableCell><div className="flex">名字<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
                        <TableCell><div className="flex">等級<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
                        <TableCell><div className="flex">英雄等級<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
                        <TableCell><div className="flex">聲望<img className='chevron' src={rankingArrow} alt='' /></div></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bestPlayersRanking.map((row, key) => (
                        <TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell >{key + 1}</TableCell>
                            <TableCell >{row.name}</TableCell>
                            <TableCell >{row.level}</TableCell>
                            <TableCell >{row.heroLevel}</TableCell>
                            <TableCell >{row.reput}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Button handler={'/ranking'} height='24px' width='77px' fontSize='12px' type='link' image={smallButtonBg}>查看更多</Button>
        </section>

    )
}

export default RankingTable