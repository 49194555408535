import React, { useEffect, useRef, useState, useContext } from 'react';
import Button from '../../../../components/Button';
import roueImg from '../../../../assets/images/icon/whell/roue.png';
import spinnerImg from '../../../../assets/images/icon/whell/spinner.png';
import dragonImg from '../../../../assets/images/icon/whell/dragon.png';
import roueShadowImg from '../../../../assets/images/icon/whell/roue-shadow.png';
import useWindowDimensions from '../../../../utils/useWindowDimensions';
import { smallButtonBg } from '../../../../utils/data';
import selectedImg from '../../../../assets/images/icon/whell/selected.png';
import { AuthContext } from '../../../../context/AuthContext';

const Item = ({ item, isWin, x, y, handleItemInfo }) => {
    const [isHover, setIsHover] = useState(false);
    const ref = useRef(null);

    const handleOnMouseEnter = () => {
        if (ref.current) {
            handleItemInfo({
                x: ref.current.getBoundingClientRect().x,
                y: ref.current.getBoundingClientRect().y,
                item: item
            });
        }
    };

    let imageUrl;
    try {
        imageUrl = require(`../../../../assets/images/items/${item?.image}.png`);
    } catch (e) {
        imageUrl = '';
    }

    return (
        <div
            ref={ref}
            onMouseEnter={() => handleOnMouseEnter()}
            onMouseLeave={() => handleItemInfo({})}
            className={isHover ? "item hover" : "item"}
            style={{
                transform: `translate(${x}px, ${y}px)`
            }}
        >
            <img
                className={isWin ? 'image won' : 'image'}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                src={imageUrl}
                alt=''
            />
        </div>
    );
};

const ItemInfo = ({ x, y, item }) => {
    return (
        <>
            {item && (
                <div className="information" style={{ left: x + 'px', top: y + 'px' }}>
                    <span>{item?.name + " x" + item?.quantity}</span>
                </div>
            )}
        </>
    );
};

const Roue = ({ items, selectedCharacter, pityCounter, setPityCounter, maxPity }) => {
    const { coins, processWheelWin } = useContext(AuthContext);
    const [radius, setRadius] = useState(265);
    const { height, width } = useWindowDimensions();
    const padding = 40;
    const [itemInfo, setItemInfo] = useState({});
    const [rotationAngle, setRotationAngle] = useState(0);
    const [itemWin, setItemWin] = useState({});
    const [showItemWin, setShowItemWin] = useState(false);
    const [isRotate, setIsRotate] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        let finalRadius = 0;

        if (width > 768) {
            finalRadius = width / 2 - (200 + padding);
        } else {
            finalRadius = width / 2 - (10 + padding);
        }

        if (finalRadius > 250 - padding) {
            setRadius(250 - padding);
        } else {
            setRadius(finalRadius);
        }
    }, [height, width]);

    const rearrangedItems = items.slice();
    const superRewardIndex = rearrangedItems.findIndex(item => item.isSuperReward === true);

    if (superRewardIndex !== -1) {
        const [superRewardItem] = rearrangedItems.splice(superRewardIndex, 1);
        rearrangedItems.unshift(superRewardItem);
    }

    const determineWinningItem = () => {
        const totalPercentage = rearrangedItems.reduce((sum, item) => sum + item.percentage, 0);
        const normalizedPercentage = totalPercentage > 100 ? 100 : totalPercentage;

        let cumulativePercentage = 0;
        const randomNum = Math.random() * 100;

        for (let i = 0; i < rearrangedItems.length; i++) {
            const item = rearrangedItems[i];
            let itemProbability = item.percentage;

            if (pityCounter === maxPity) {
                return 0;
            }

            cumulativePercentage += (itemProbability / normalizedPercentage) * 100;

            if (randomNum <= cumulativePercentage) {
                return i;
            }
        }

        return rearrangedItems.length - 1;
    };

    const handleSpinne = () => {
        const spinCost = parseInt(process.env.REACT_APP_WHEEL_TURN, 10);
        if (coins < spinCost) {
            setError(`您需要 ${process.env.REACT_APP_WHEEL_TURN} 硬幣來轉動輪盤。`);
            return;
        }

        if (!isRotate) {
            setIsRotate(true);
            const itemLength = rearrangedItems.length;
            const win = determineWinningItem();

            if (win === 0) {
                setPityCounter(0);
            } else if (pityCounter < maxPity) {
                setPityCounter(pityCounter + 1);
            }

            const anglePerItem = 360 / itemLength;
            const pointerOffset = (anglePerItem / 2) - 9;
            const finalAngle = (anglePerItem * win) + (360 * 3) - pointerOffset;
            
            processWheelWin(process.env.REACT_APP_WHEEL_TURN, selectedCharacter, rearrangedItems[win]);
        
            let currentAngle = 0;
            let speed = 7;
            const acceleration = 0.98;
            const minSpeed = 1;
        
            const spinInterval = setInterval(() => {
                if (currentAngle >= finalAngle - 360) {
                    speed = Math.max(speed * acceleration, minSpeed);
                }
                currentAngle += speed;
                if (currentAngle >= finalAngle) {
                    clearInterval(spinInterval);
                    setRotationAngle(finalAngle % 360);
                    setItemWin(rearrangedItems[win]);
                    setShowItemWin(true);
                    setTimeout(() => {
                        setIsRotate(false);
                    }, 2000);
                } else {
                    setRotationAngle(currentAngle % 360);
                }
            }, 16);
        }
    };

    let itemWinImageUrl;
    try {
        itemWinImageUrl = require(`../../../../assets/images/items/${itemWin.image}.png`);
    } catch (e) {
        itemWinImageUrl = '';
    }

    const calculateTransformOrigin = (width) => {
        const basePercentage = 115;
        const adjustmentFactor = 1.3;
        
        return width > 768 ? 'center 115%' : `center ${(basePercentage + adjustmentFactor * (768 / width)).toFixed(1)}%`;
    };

    return (
        <>
            <ItemInfo item={itemInfo.item} x={itemInfo.x} y={itemInfo.y} />

            <div id="roue" style={{ height: (radius + padding) * 2 + 'px', width: (radius + padding) * 2 + 'px' }}>
                <img className='dragon' src={dragonImg} alt='' />

                <div className="well-bg image" style={{ backgroundImage: `url(${roueImg})` }}></div>

                <img
                    className='selectedImg'
                    src={selectedImg}
                    alt=''
                    style={{
                        transform: `rotate(${rotationAngle}deg)`,
                        transformOrigin: calculateTransformOrigin(width),
                        width: width > 768 ? 'auto' : `${width * 0.16}px`
                    }}
                />

                <div className="items">
                    {rearrangedItems.map((item, key) => {
                        const startAngle = -90;
                        const angle = startAngle + key * (360 / items.length);
                        const x = Math.round(radius * Math.cos((angle * Math.PI) / 180) - 25 / 2);
                        const y = Math.round(radius * Math.sin((angle * Math.PI) / 180) - 25 / 2);

                        return (
                            <Item
                                handleItemInfo={(e) => setItemInfo(e)}
                                isWin={item.image === itemWin.image}
                                key={key}
                                x={x}
                                y={y}
                                item={item}
                            />
                        );
                    })}
                    <div className="roue-shadow-container">
                        <img className='roue-shadow' src={roueShadowImg} alt='' />
                    </div>
                </div>

                <div className="spinner-container" onClick={() => handleSpinne()}>
                    <img src={spinnerImg} alt='' />
                </div>

            </div>
            {showItemWin &&
                <div className="itemWin">
                    <div className="section-container">
                        <h2>幸運轉盤獎勵</h2>
                        <div className="main-divider"></div>
                        <img src={itemWinImageUrl} alt='' />
                        <p>{itemWin.name + " x" + itemWin.quantity}</p>
                        <Button height='30px' width='90px' fontSize='14px' image={smallButtonBg} handler={() => { setShowItemWin(false); setItemWin({}) }}>關閉</Button>
                    </div>
                </div>
            }
            {error &&
                <div className="itemWin">
                    <div className="section-container">
                        <h2>硬幣不足</h2>
                        <div className="main-divider"></div>
                        <p>{error}</p>
                        <Button height='30px' width='90px' fontSize='14px' image={smallButtonBg} handler={() => { setError('') }}>關閉</Button>
                    </div>
                </div>
            }
        </>
    );
};

export default Roue;