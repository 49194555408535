import React, { useState } from 'react';
import ItemsCard from './components/ItemsCard';
import Section from '../../components/Section/Section';
import Filters from './components/Filters';
import { motion } from 'framer-motion';
import Scrollbar from '../../components/Scrollbar/Scrollbar';

const transition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
const translateY = 100;

const Shop = ({ items = [], character = [], categories = [] }) => {
    const [filteredItems, setFilteredItems] = useState(items);

    const handleFilterChange = (selectedOption) => {
        if (!selectedOption || !selectedOption.value) {
            setFilteredItems(items);
        } else {
            const filtered = items.filter(item => item.subCategoryId === selectedOption.value);
            setFilteredItems(filtered);
        }
    };

    return (
        <Section id='shop'>
            <div className="shop-container custom-scrollbar">
                <motion.div
                    initial={{ opacity: 0, y: -translateY }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -translateY }}
                    transition={transition}
                    style={{ zIndex: 9999 }}
                >
                    <Filters categories={categories} onFilterChange={handleFilterChange} />
                </motion.div>
                <Scrollbar height='497px'>
                    <motion.div
                        initial={{ opacity: 0, y: translateY }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: translateY }}
                        transition={transition}
                        className="items"
                        style={{ zIndex: 1 }}
                    >
                        <div className="items-container">
                            {filteredItems.map((item, key) => (
                                <ItemsCard key={key} item={item} character={character} />
                            ))}
                        </div>
                    </motion.div>
                </Scrollbar>
            </div>
        </Section>
    );
};

export default Shop;
